import React from 'react';
import { Link } from 'gatsby';
import styled, { keyframes } from 'styled-components';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import SEO from '../components/seo';

import {
  H2,
  H3,
  P,
  P2,
  BackButton,
  StyledLink,
} from '../components/page-elements';
import Container from '../components/container';
import Layout from '../components/layout';

// Styled Components
const StyledContainer = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing[20]};
  text-align: center;

  @media (min-width: 48em) {
    margin-top: ${({ theme }) => theme.spacing[48]};
  }
`;

const QuestionTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-family: ${({ theme }) => theme.font.headings};
  font-weight: ${({ theme }) => theme.fontWeight.black};
  color: ${({ theme }) => theme.colors.secondary[300]};
  text-transform: uppercase;
  text-align: left;
  letter-spacing: ${({ theme }) => theme.letterSpacing.widest};

  &:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 20px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
    transition: transform 0.3s ease-in-out;
  }
`;

const fadeIn = keyframes`
    0% { opacity: 0 }
    100% { opacity: 1 }
`;

const QuestionAnswer = styled(P2)`
  text-align: left;
  animation: ${fadeIn} 0.4s ease-in-out;
  margin-bottom: ${({ theme }) => theme.spacing[3]};
`;

const SAccordian = styled(Accordion)`
  /* border: 1px solid red; */
  border-radius: ${({ theme }) => theme.borderRadius.default};
  overflow: hidden;
  /* width: 70%; */
  margin: 0 auto ${({ theme }) => theme.spacing[32]} auto;
  /* border: 1px solid ${({ theme }) => theme.colors.secondary[600]}; */
`;

const SAccordionItem = styled(AccordionItem)`
  border-top: 1px solid ${({ theme }) => theme.colors.secondary[700]};
`;

const SAccordionItemHeading = styled(AccordionItemHeading)`
  /* background: ${({ theme }) => theme.colors.secondary[800]}; */
  background-color: ${({ theme }) => theme.colors.secondary[800]};
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%23f1f7f8' fill-opacity='0.4'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");

  cursor: pointer;
`;

const SAccordionItemButton = styled(AccordionItemButton)`
  padding: ${({ theme }) => theme.spacing[6]}
    ${({ theme }) => theme.spacing[12]};

  &:focus {
    outline: none;
  }

  &[aria-expanded='true'],
  &[aria-selected='true'] {
    & > h3::before {
      transform: rotate(45deg);
    }
  }
`;

const SAccordionItemPanel = styled(AccordionItemPanel)`
  background: ${({ theme }) => theme.colors.secondary[900]};
  padding: ${({ theme }) => theme.spacing[10]}
    ${({ theme }) => theme.spacing[12]};
`;

const TenQPage = () => {
  const members = 0;
  return (
    <>
      <Layout>
        <SEO title="Polling Questions" />
        <StyledContainer>
          <H2>10 Questions</H2>
          <H3>you should ask about poll results.</H3>
          <P style={{ textAlign: `left`, maxWidth: `100%` }}>
            Polls provide a good source of information about public opinion, and
            can be valuable tools for a basis for accurate, informative news
            stories. However, it is important that you are happy that the poll
            resu lts are accurate before they are published or taken seriously.
            To help aid distinguishing properly conducted scientific polls,
            AIMRO have put together 10 questions to ask before reporting any
            results. This publication is designed to help you to judge wha t
            poll results you should take seriously.
          </P>
          <SAccordian allowZeroExpanded>
            <SAccordionItem style={{ border: `none` }}>
              <SAccordionItemHeading>
                <SAccordionItemButton>
                  <QuestionTitle>1. Who did the poll?</QuestionTitle>
                </SAccordionItemButton>
              </SAccordionItemHeading>
              <SAccordionItemPanel>
                <QuestionAnswer>
                  Reputable polling firms will provide you with the information
                  you need to evaluate the survey. Because reputation is
                  important to a quality firm, a professionally conducted poll
                  will avoid any errors.
                </QuestionAnswer>
                <QuestionAnswer>
                  Check if the company is a member of AIMRO or ESOMAR , and is a
                  legitimate market research organisation that conducts research
                  professionally outside of published polls.
                </QuestionAnswer>
              </SAccordionItemPanel>
            </SAccordionItem>
            <SAccordionItem>
              <SAccordionItemHeading>
                <SAccordionItemButton>
                  <QuestionTitle>2. Why are polls conducted ?</QuestionTitle>
                </SAccordionItemButton>
              </SAccordionItemHeading>
              <SAccordionItemPanel>
                <QuestionAnswer>
                  Many organizations want to learn what the public thinks. Those
                  organizations include the government itself, the political
                  parties, companies, non-profit and charitable organisations,
                  and the news media. In a democracy, knowing public opinion
                  helps parties to choose an agenda to campaign on, and gives
                  government information on whether their policies are approved
                  by the electorate.
                </QuestionAnswer>
                <QuestionAnswer>
                  Regardless of who commissions the poll, all polls need to be
                  done well, otherwise they will give a distorted view of
                  opinion (for example, if news organizations only interview
                  people who visit their website, they are missing a large
                  component of the public).
                </QuestionAnswer>
                <QuestionAnswer>
                  Finally, the public itself should care about national opinion.
                  Since this information is being used by decision-makers,
                  well-done opinion polls that are made public give the people
                  access to the same type of information that governments and
                  politicians use. Polls provide information that may affect the
                  public. Well-done and properly reported-on opinion polls
                  democratize information.
                </QuestionAnswer>
              </SAccordionItemPanel>
            </SAccordionItem>
            <SAccordionItem>
              <SAccordionItemHeading>
                <SAccordionItemButton>
                  <QuestionTitle>
                    3 . How were people chosen for the poll ?
                  </QuestionTitle>
                </SAccordionItemButton>
              </SAccordionItemHeading>
              <SAccordionItemPanel>
                <QuestionAnswer>
                  One of the key reasons that some polls reflect public opinion
                  accurately and other polls are a poor representation of
                  opinion is how people were chosen to be interviewed.
                </QuestionAnswer>
                <QuestionAnswer>
                  In scientific polls, the pollster uses a specific statistical
                  method for picking respondents.
                </QuestionAnswer>
                <QuestionAnswer>
                  In unscientific polls, the person either picks himself to
                  participate, by phone in or opt in; or people are called or
                  stopped on the street without any statistical method or
                  safeguards in place to ensure that the people picked for the
                  poll represent the population in question.
                </QuestionAnswer>
                <QuestionAnswer>
                  It’s no good just ringing 500 or a 1000 people from your
                  address book for a poll, as this will only be representative
                  of your address book, not everyone in the country.
                </QuestionAnswer>
                <QuestionAnswer>
                  To make sure a poll reaches an accurate sample of people, you
                  need to ensure that everyone you want to represent has an
                  equal chance of being selected. Even then you also need to
                  make sure the final sample spoken to represents your target.
                </QuestionAnswer>
                <QuestionAnswer>
                  Reputable polling companies use a combination of
                  random/selective sampling and quotas to obtain accurate polls.
                </QuestionAnswer>
              </SAccordionItemPanel>
            </SAccordionItem>
            <SAccordionItem>
              <SAccordionItemHeading>
                <SAccordionItemButton>
                  <QuestionTitle>
                    4. How many people were interviewed for the survey?{' '}
                  </QuestionTitle>
                </SAccordionItemButton>
              </SAccordionItemHeading>
              <SAccordionItemPanel>
                <QuestionAnswer>
                  Bigger isn’t necessarily better.
                </QuestionAnswer>
                <QuestionAnswer>
                  Because polls give approximate answers, the more people
                  interviewed in a scientific poll, the smaller the error due to
                  the size of the sample, all other things being equal.
                </QuestionAnswer>
                <QuestionAnswer>
                  However a common trap to avoid is that this means "more is
                  automatically better." While it is absolutely true that the
                  more people interviewed in a scientific survey the smaller the
                  sampling error, however a poorly conducted poll with very
                  large sample can give you a very wrong result. Other factors
                  may in this case be more important in judging the quality of a
                  survey, than sample size.
                </QuestionAnswer>
                <QuestionAnswer>
                  AIMRO advises that for a properly conducted scientific
                  national poll, a minimum sample of 1000 interviews is required
                  and 500 interviews for a published local area poll.
                </QuestionAnswer>
              </SAccordionItemPanel>
            </SAccordionItem>
            <SAccordionItem>
              <SAccordionItemHeading>
                <SAccordionItemButton>
                  <QuestionTitle>
                    5. How can a poll of 1000 people be representative of all
                    the electorate?
                  </QuestionTitle>
                </SAccordionItemButton>
              </SAccordionItemHeading>
              <SAccordionItemPanel>
                <QuestionAnswer>
                  As long as a sample is taken randomly and quotas used to
                  ensure that it is representative of all adults, the size of
                  the universe doesn’t matter; rather the margin of error is
                  based on the size of the sample.
                </QuestionAnswer>
                <QuestionAnswer>
                  To give an example of this, if you make a cake with lots of
                  different ingredients, as long as it is mixed properly, you do
                  not need to eat the whole cake to see what it tastes like;
                  just a small slice will tell you within a margin of error what
                  the rest of the cake will taste like.
                </QuestionAnswer>
                <QuestionAnswer>
                  This is also well illustrated in a quote made by a famous US
                  market researcher which reads, “If you don’t believe in random
                  sampling, next time you are in for a blood test, ask the
                  doctor to take it all.”
                </QuestionAnswer>
              </SAccordionItemPanel>
            </SAccordionItem>
            <SAccordionItem>
              <SAccordionItemHeading>
                <SAccordionItemButton>
                  <QuestionTitle>
                    6. What is the possible error for the poll results?{' '}
                  </QuestionTitle>
                </SAccordionItemButton>
              </SAccordionItemHeading>
              <SAccordionItemPanel>
                <QuestionAnswer>
                  A properly conducted poll of 1000 interviews provides a sample
                  error of just + or -3% at 95% confidence level. If we increase
                  the sample size the sample error only falls very slowly after
                  this, for example a sample of 2000 interviews has a margin of
                  error of around + or – 2%.
                </QuestionAnswer>
                <QuestionAnswer>
                  Local or regional polls can be conducted on a slightly smaller
                  sample size of 500 interviews. However this does increase the
                  possible error to + or – 4.5%.
                </QuestionAnswer>
                <QuestionAnswer>
                  Unscientific polls conducted without random selection and
                  proper quotas set on who is interviewed, will have a much
                  higher error, and may produce very misleading findings.
                </QuestionAnswer>
              </SAccordionItemPanel>
            </SAccordionItem>
            <SAccordionItem>
              <SAccordionItemHeading>
                <SAccordionItemButton>
                  <QuestionTitle>
                    7. How were the interviews conducted?{' '}
                  </QuestionTitle>
                </SAccordionItemButton>
              </SAccordionItemHeading>
              <SAccordionItemPanel>
                <QuestionAnswer>
                  Firstly it is important to make sure that the poll was
                  conducted outbound by a company, rather than a phone in or
                  self completion poll. In this case the polling company decides
                  whether a person should be interviewed or not.
                </QuestionAnswer>
                <QuestionAnswer>
                  These types of polls are normally conducted face to face, by
                  phone or online. All methods are valid means of conducting
                  polls, as long as they have followed the scientific approach
                  that meets all the previous points made – such as random
                  selection, ensuring everyone being represented by the poll can
                  be selected, and setting quotas to ensure the poll is
                  representative.
                </QuestionAnswer>
              </SAccordionItemPanel>
            </SAccordionItem>
            <SAccordionItem>
              <SAccordionItemHeading>
                <SAccordionItemButton>
                  <QuestionTitle>
                    8. What area (national or region/constituency) were the
                    people chosen from?
                  </QuestionTitle>
                </SAccordionItemButton>
              </SAccordionItemHeading>
              <SAccordionItemPanel>
                <QuestionAnswer>
                  Because polls aim to represent certain audiences, it is also
                  important for to know who the poll is trying to represent.
                </QuestionAnswer>
                <QuestionAnswer>
                  For example, you need to know if a sample was drawn from among
                  all adults, or just from those in one constituency or in one
                  city.
                </QuestionAnswer>
                <QuestionAnswer>
                  A survey of people in Dublin can only reflect the opinions of
                  people in Dublin – not of all adults.
                </QuestionAnswer>
              </SAccordionItemPanel>
            </SAccordionItem>
            <SAccordionItem>
              <SAccordionItemHeading>
                <SAccordionItemButton>
                  <QuestionTitle>9. When was the poll done? </QuestionTitle>
                </SAccordionItemButton>
              </SAccordionItemHeading>
              <SAccordionItemPanel>
                <QuestionAnswer>
                  Events have a dramatic impact on poll results. Your
                  interpretation of a poll should depend on when it was
                  conducted relative to key events. Even the freshest poll
                  results can be overtaken by events.
                </QuestionAnswer>
                <QuestionAnswer>
                  Poll results that are several weeks or months old may be
                  perfectly valid, but events may have erased any newsworthy
                  relationship to current public opinion.
                </QuestionAnswer>
              </SAccordionItemPanel>
            </SAccordionItem>
            <SAccordionItem>
              <SAccordionItemHeading>
                <SAccordionItemButton>
                  <QuestionTitle>10. What questions were asked?</QuestionTitle>
                </SAccordionItemButton>
              </SAccordionItemHeading>
              <SAccordionItemPanel>
                <QuestionAnswer>
                  Reputable polling companies always ensure that questions are
                  phrased that do not bias the result one way or another, and
                  allow the voter to agree or disagree with the topic.
                </QuestionAnswer>
                <QuestionAnswer>
                  Perhaps the best test of any poll question is your reaction to
                  it. On the face of it, does the question seem fair and
                  unbiased? Does it present a balanced set of choices? Would
                  most people be able to answer the question?
                </QuestionAnswer>
              </SAccordionItemPanel>
            </SAccordionItem>
          </SAccordian>
          <StyledLink to="/">
            <BackButton>Return Home</BackButton>
          </StyledLink>
        </StyledContainer>
      </Layout>
    </>
  );
};

export default TenQPage;
